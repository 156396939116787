import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Rating,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import he from "he";
import Grid from "@mui/material/Unstable_Grid2";
import ActivityRes from "../../components/responsive/activityRes";

export default function Activity({ dataActivity }: any) {
  const thumb1 = "/assets/images/thumbnail/thume5.png";
  const [isLoading, setIsLoading] = useState(true);
  const [visibleProducts, setVisibleProducts] = useState([]);

  const decodeMultipleTimes = (str: any) => {
    let decoded = he.decode(str);
    while (decoded !== str) {
      str = decoded;
      decoded = he.decode(str);
    }
    return decoded;
  };

  useEffect(() => {
    const updateVisibleProducts = () => {
      const width = window.innerWidth;
      let numberOfCards;

      if (width >= 2160) {
        numberOfCards = 6;
      } else if (width >= 1920) {
        numberOfCards = 5;
      } else if (width >= 1440) {
        numberOfCards = 4;
      } else if (width <= 1400) {
        numberOfCards = 3;
      } else if (width <= 1024) {
        numberOfCards = 3;
      } else if (width == 768) {
        numberOfCards = 3;
      } else {
        numberOfCards = dataActivity?.length;
      }

      setVisibleProducts(dataActivity?.slice(0, numberOfCards));
    };

    updateVisibleProducts();

    window.addEventListener("resize", updateVisibleProducts);

    return () => {
      window.removeEventListener("resize", updateVisibleProducts);
    };
  }, [dataActivity]);

  const aspectRatio = 3 / 2; // เปลี่ยนเป็นอัตราส่วนภาพ 3:2 // ตัวอย่างอัตราส่วนภาพ
  const width = 200; // กำหนดความกว้าง container
  const height = width / aspectRatio;

  return (
    <>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{
          pt: {
            xs: "0px",
            md: "20px",
          },
        }}
      >
        <Grid xs={12} md={12} sx={{ pt: "30px", pb: "1rem" }}>
          <Typography
            variant="h2"
            className="txt-bold"
            sx={{
              fontSize: { xs: "20px", md: "25px" },
              pt: { xs: "10px", md: "0" },
              pb: { xs: "10px", md: "0" },
            }}
          >
            กิจกรรม
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ display: { xs: "none", md: "flex" } }}>
        <Grid xs={12} sm={12} md={12} lg={12}>
          <Box
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "repeat(2, 1fr)",
              width: "100%",
              mt: "15px",
              "@media (min-width: 768px)": {
                gridTemplateColumns: "repeat(3, 1fr)",
              },
              "@media (min-width: 1024px)": {
                gridTemplateColumns: "repeat(3, 1fr)",
              },
              "@media (min-width: 1440px)": {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              "@media (min-width: 1920px)": {
                gridTemplateColumns: "repeat(5, 1fr)",
              },
              "@media (min-width: 2160px)": {
                gridTemplateColumns: "repeat(6, 1fr)",
              },
            }}
          >
            {visibleProducts?.map((data: any) => (
              <Link
                href={`/community/article/${data.slug}`}
                key={data?.product_id}
              >
                <Card
                  className="box-shadow-center"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 0 auto",
                    height: "100%",
                    mx: 0,
                    scrollSnapAlign: "start",
                    overflow: "hidden",
                    transition: "transform 0.3s, border 0.3s",
                    "&:hover": {
                      borderColor: "#f6cd64",
                      transform: "translateY(-2px)",
                    },
                    borderRadius: "12px",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    position="relative"
                    sx={{ width: "100%", height: "auto", borderRadius: "12px" }}
                  >
                    {isLoading && (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "12px",
                        }}
                      />
                    )}
                    <Image
                        src={data.desktopCoverImageFullPath || thumb1}
                        alt={data.articleTitle}
                        width={width}
                        height={height}
                        layout="responsive"
                        objectFit="cover"
                        style={{
                            borderRadius: "12px",
                            objectFit: 'cover',
                        }}
                        loading="lazy"
                        placeholder="empty"
                        blurDataURL={data.desktopCoverImageFullPath || thumb1}
                        onLoadingComplete={() => setIsLoading(false)}
                    />
                  </Box>

                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h3"
                      sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 2,
                        whiteSpace: "normal",
                        color: "#000000",
                        fontFamily:
                          "NotoSans-SemiBold, NotoSansThai-SemiBold !important",
                        fontSize: "18px",
                        lineHeight: "22px",
                        mb: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {data.articleTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#717171",
                        fontSize: "16px",
                        WebkitLineClamp: 3,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                      }}
                    >
                      {decodeMultipleTimes(
                        data.articleShortDesc.replace(/<\/?[^>]+(>|$)/g, "")
                      )}
                    </Typography>
                  </CardContent>

                  <CardContent
                    sx={{
                      // p: 0,
                      // pl: "12px",
                      // pt: "5px",
                      // mt:"15px"
                      paddingBottom: "15px !important"
                    }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        alt=""
                        sx={{ width: 20, height: 20 }}
                        src={data.writer.WriterImageFullPath}
                      />
                      <Typography sx={{ fontSize: 12 }} color="text.secondary">
                        By {data.writer.writerName}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }} color="text.secondary">
                        {data.createdAt}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Link>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ display: { xs: "flex", md: "none" } }}>
        <Grid xs={12} sm={12} md={12} lg={12}>
          <ActivityRes dataActivity={dataActivity} />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, mb: 4 }} className="pagination">
        <Link href={`/community/activity`}>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              borderColor: "#DEE2E6",
              width: "300px",
              color: "#292929",
              "&:hover": {
                color: "#DB5A00",
                backgroundColor: "#ffffff",
              },
            }}
          >
            <Typography>ดูเพิ่มเติม</Typography>
          </Button>
        </Link>
      </Box>
    </>
  );
}
