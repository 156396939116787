import { Config } from '@/config'
import { Box, Container, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react'
import Link from "next/link";

interface LinkToPageProps {
	handleMap: () => void;
}

export default function LinkTopage({ handleMap }: LinkToPageProps) {

	return (
		<Grid container>
			<Grid xs={12} sx={{ py: 1 }}>
				<Box 
					sx={{ 
						width: "100%", display: 'flex', 
					// 	flexDirection: { 
					// 		xs: 'row', sm: 'row' 
					// 	}, 
						justifyContent: 'right', 
						alignItems: 'right'
					}}
				>
					<Link className="inline-block" style={{ padding: ".25rem .5rem" }} href={"/coupon"}>
						<Typography sx={{ fontSize: "14px" }}>
							คูปอง
						</Typography>
					</Link>
					<Box className="inline-block" style={{ padding: ".25rem .5rem" }} onClick={handleMap} sx={{ cursor: "pointer" }}>
						<Typography sx={{ fontSize: "14px", '&:hover': { color: "#db5a00" } }} >
							ค้นหาสถานที่บริการ
						</Typography>
					</Box>
					<Link className="inline-block" style={{ padding: ".25rem .5rem" }} href={"/community/article"}>
						<Typography sx={{ fontSize: "14px" }}>
							บทความ
						</Typography>
					</Link>
					<Link className="inline-block" style={{ padding: ".25rem .5rem" }} href={"/community/activity"}>
						<Typography sx={{ fontSize: "14px" }}>
							กิจกรรม
						</Typography>
					</Link>
					<Link className="inline-block" style={{ padding: ".25rem .5rem" }} href={"/community/video"}>
						<Typography sx={{ fontSize: "14px" }}>
							วิดีโอ
						</Typography>
					</Link>
					<Link className="inline-block" style={{ padding: ".25rem .5rem" }} href={Config.apiSellerEndpoint}>
						<Typography sx={{ fontSize: "14px" }}>
							เริ่มขายสินค้า/บริการ
						</Typography>
					</Link>
				</Box>
			</Grid>
		</Grid>
	)
}