import React, { useEffect, useState } from "react";
import {
  Typography,
  CardContent,
  Card,
  CardMedia,
  Stack,
  Box,
  CardActions,
  Rating,
  useTheme,
  Button,
  IconButton,
  Skeleton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "next/link";
import Avatar from "@mui/material/Avatar";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Pagination, Autoplay } from "swiper";
import Image from "next/image";
import { } from "../../interfaces/community/article";
import { Config, ConfigMember } from "@/config";
import ReactHtmlParser from "react-html-parser";
import he from "he";
import { log } from "console";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArticleRes from "../responsive/articleRes";

interface Props {
  dataSet: {
    data: {
      created_at: string;
      id: number;
      article_title: string;
      article_short_desc: string;
      slug: string;
      writer_id: number;
      status: number;
      desktop_cover_image: string;
      mobile_cover_image: string;
      article_tag_relation: {
        tag_id: number;
        article_tag: {
          tag_title: string;
        };
      };
      writer: {
        id: number;
        writer_name: string;
        writer_image: string;
      };
    };
  };
}

interface Item {
  created_at: string;
  id: number;
  article_title: string;
  article_short_desc: string;
  slug: string;
  writer_id: number;
  status: number;
  desktop_cover_image: string;
  mobile_cover_image: string;
  article_tag_relation: {
    tag_id: number;
    article_tag: {
      tag_title: string;
    };
  };
  writer: {
    id: number;
    writer_name: string;
    writer_image: string;
  };
}

export default function Article({ dataSet }: any) {
  const leftGridData = dataSet?.slice(0, 1);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleProducts, setVisibleProducts] = useState([]);

  const decodeMultipleTimes = (str: any) => {
    let decoded = he.decode(str);
    while (decoded !== str) {
      str = decoded;
      decoded = he.decode(str);
    }
    return decoded;
  };

  useEffect(() => {
    const updateVisibleProducts = () => {
      const width = window.innerWidth;
      let numberOfCards;

      if (width >= 2160) {
        numberOfCards = 9;
      } else if (width >= 1920) {
        numberOfCards = 9;
      } else if (width >= 1440) {
        numberOfCards = 7;
      } else if (width <= 1400) {
        numberOfCards = 5;
      }
      else if (width >= 1024) {
        numberOfCards = 5;
      } else {
        numberOfCards = dataSet.length;
      }

      setVisibleProducts(dataSet.slice(1, numberOfCards));
    };

    updateVisibleProducts();

    window.addEventListener("resize", updateVisibleProducts);

    return () => {
      window.removeEventListener("resize", updateVisibleProducts);
    };
  }, [dataSet]);


  if (dataSet == null) {
    return "";
  }

  if (typeof dataSet === "object" && dataSet !== null && "message" in dataSet) {
    return dataSet.message;
  }

  const aspectRatio = 3 / 2; // เปลี่ยนเป็นอัตราส่วนภาพ 3:2 // ตัวอย่างอัตราส่วนภาพ
  const width = 420; // กำหนดความกว้าง container
  const height = width / aspectRatio;

  return (
    <>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ pt: { xs: "0px", md: "20px" } }}
      >
        <Grid xs={12} md={12} sx={{ pt: "30px", pb: "1rem" }}>
          <Typography
            variant="h2"
            className="txt-bold"
            sx={{
              fontSize: { xs: "20px", md: "25px" },
              py: { xs: "10px", md: "0" }
            }}
          >
            บทความ
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Grid xs={4} sm={12} md={6} lg={5} xl={4}>
          {leftGridData.slice(0, 1).map((data: any, idx: number) => (
            <Link href={`/community/article/${data.slug}`} passHref key={idx}>
              <Card
                className="box-shadow-center"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  overflow: "hidden",
                  borderRadius: "14px",
                }}
              >
                <Box position="relative" sx={{ width: "100%", height: "auto" }}>
                  {isLoading && (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                  <Image
                    src={data.desktopCoverImageFullPath}
                    alt={data.articleTitle}
                    width={width}
                    height={height}
                    layout="responsive"
                    objectFit="cover"
                    loading="lazy"
                    placeholder="empty"
                    blurDataURL={data.desktopCoverImageFullPath}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                    onLoadingComplete={() => setIsLoading(false)}
                  />
                </Box>

                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h3"
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                      whiteSpace: "normal",
                      color: "#000000",
                      fontFamily:
                        "NotoSans-SemiBold, NotoSansThai-SemiBold !important",
                      fontSize: "18px",
                      lineHeight: "22px",
                      mb: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    {data.articleTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#717171",
                      fontSize: "16px",
                      WebkitLineClamp: 3,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                    }}
                  >
                    {decodeMultipleTimes(
                      data.articleShortDesc.replace(/<\/?[^>]+(>|$)/g, "")
                    )}
                  </Typography>
                </CardContent>

                <CardContent
                  sx={{
                    p: 0,
                    pl: "12px",
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                      alt=""
                      sx={{ width: 20, height: 20 }}
                      src={data.writer.WriterImageFullPath}
                    />
                    <Typography sx={{ fontSize: 12 }} color="text.secondary">
                      By {data.writer.writerName}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} color="text.secondary">
                      {data.createdAt}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Link>
          ))}
        </Grid>

        <Grid xs={8} sm={12} md={6} lg={7} xl={8}>
          <Box
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "repeat(2, 1fr)",
              width: "100%",
              "@media (min-width: 1024px)": {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
              "@media (min-width: 1440px)": {
                gridTemplateColumns: "repeat(3, 1fr)",
              },
              "@media (min-width: 1920px)": {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              "@media (min-width: 2160px)": {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
            }}
          >
            {visibleProducts?.map((data: any, idx: number) => (
              <>
                <Link href={`/community/article/${data.slug}`} passHref>
                  <Card
                    className="product-card-best"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      overflow: "hidden",
                      transition: "transform 0.3s, border 0.3s",
                      "&:hover": {
                        borderColor: "#f6cd64",
                        transform: "translateY(-2px)",
                      },
                      borderRadius: "14px",
                      width: "100%",
                      // minHeight: "30px",
                      minHeight: "auto !important"
                    }}
                  >
                    <Box
                      position="relative"
                      sx={{ width: "100%", height: "auto" }}
                    >
                      {isLoading && (
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      )}
                      <Image
                        src={data.desktopCoverImageFullPath}
                        alt={data.articleTitle}
                        width={width}
                        height={height}
                        layout="responsive"
                        objectFit="cover"
                        loading="lazy"
                        placeholder="empty"
                        blurDataURL={data.desktopCoverImageFullPath}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                        onLoadingComplete={() => setIsLoading(false)}
                      />
                    </Box>

                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        position: "relative",
                        minHeight: "106px",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h3"
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 2,
                          whiteSpace: "normal",
                          color: "#000000",
                          fontFamily:
                            "NotoSans-SemiBold, NotoSansThai-SemiBold !important",
                          fontSize: "18px",
                          lineHeight: "22px",
                          mb: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {data.articleTitle}
                      </Typography>
                     
                    </CardContent>
                    <CardContent
                      sx={{
                      //   p: 0,
                      //   pl: "12px",
                      // minHeight:"30px",
                      // maxHeight:"30px",
                      // height:"30px"
                        p: "15px",
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center"
                        sx={{
                          position: "absolute",
                          bottom: "15px"
                        }}
                      >
                        <Avatar
                          alt=""
                          sx={{ width: 20, height: 20 }}
                          src={data.writer.WriterImageFullPath}
                        />
                        <Typography sx={{ fontSize: 12, ml: "8px !important" }} color="text.secondary">
                          By {data.writer.writerName}
                        </Typography>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                          {data.createdAt}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Link>
              </>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
            // xl:"flex",
            // sm:"flex",
            // lg:"flex"
          },
        }}
      >
        <ArticleRes dataSet={dataSet} />
      </Box>
      <Box sx={{ mt: 4 }} className="pagination">
        <Link href={`/community/article`}>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              borderColor: "#DEE2E6",
              width: "300px",
              color: "#292929",
              "&:hover": {
                color: "#DB5A00",
                backgroundColor: "#ffffff",
              },
            }}
          >
            <Typography>ดูเพิ่มเติม</Typography>
          </Button>
        </Link>
      </Box>
    </>
  );
}
