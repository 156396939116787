import { isProduction } from '@/config';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Card, CardContent, Typography, Stack, CardActions, Rating, Grid, Skeleton } from '@mui/material';
import router from 'next/router';
import React, { useRef, useState } from 'react'
import Image from "next/image";

interface ProductItem {
    item_id: string;
    item_name: string;
    affiliation: string | null;
    coupon: string | null;
    discount: number;
    index: number;
    item_brand: string | null;
    item_category: string | null;
    item_category2: string | null;
    item_category3: string | null;
    item_category4: string | null;
    item_category5: string | null;
    item_list_id: string;
    item_list_name: string;
    item_variant: string | null;
    location_id: string | null;
    price: number;
    quantity: number;
}

export default function Productservicenew({ dataset }: any) {
    const [isLoading, setIsLoading] = useState(true);
    const imgDefault = "/assets/images/logo/logomaboom.png"
    const scrollContainer = useRef(null);
    const handleScroll = (direction: string, scrollContainer: React.RefObject<HTMLDivElement>) => {
        if (scrollContainer?.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;


            const scrollAmount = clientWidth;
            const maxScrollLeft = scrollWidth - clientWidth;

            if (direction === 'left') {
                scrollContainer.current.scrollBy({
                    left: -scrollAmount,
                    behavior: 'smooth',
                });
            } else if (direction === 'right') {
                if (scrollLeft + clientWidth >= scrollWidth) {

                    scrollContainer.current.scrollTo({
                        left: 0,
                        behavior: 'auto',
                    });
                }

                scrollContainer.current.scrollBy({
                    left: scrollAmount,
                    behavior: 'smooth',
                });
            }
        }
    };


    const formatPrice = (price: number, digits: number = 2): number =>
        parseFloat(price.toFixed(digits));

    const itemList = {
        itemListId: "new_products",
        itemListName: "สินค้า/บริการมาใหม่",
    };

    const handleProductClick = (
        item: any,
        itemList: { itemListId: string; itemListName: string }
    ): void => {
        if (!item) {
            if (!isProduction) {
                console.warn("No product data available.");
            }
            return;
        }

        // Map product data and format it
        const items: ProductItem[] = dataset?.map((item: any, idx: number) => {
            const {
                product_id,
                product_title,
                product_discounted_price,
                product_normal_price,
                affiliation = null,
                coupon = null,
                item_brand = null,
                item_category = null,
                item_category2 = null,
                item_category3 = null,
                item_category4 = null,
                item_category5 = null,
                item_variant = null,
                location_id = null,
            } = item;

            // Calculate price and discount
            const isDiscounted = product_discounted_price > 0;
            const price = formatPrice(
                isDiscounted ? product_discounted_price : product_normal_price
            );
            const discount = formatPrice(
                isDiscounted ? product_normal_price - product_discounted_price : 0
            );

            return {
                item_id: product_id,
                item_name: product_title,
                ...(affiliation && { affiliation }),
                ...(coupon && { coupon }),
                discount,
                index: idx,
                ...(item_brand && { item_brand }),
                ...(item_category && { item_category }),
                ...(item_category2 && { item_category2 }),
                ...(item_category3 && { item_category3 }),
                ...(item_category4 && { item_category4 }),
                ...(item_category5 && { item_category5 }),
                item_list_id: itemList.itemListId,
                item_list_name: itemList.itemListName,
                ...(item_variant && { item_variant }),
                ...(location_id && { location_id }),
                price,
                quantity: 1, // Default quantity to 1
            };
        });

        // Check if items array is valid
        if (!items || !Array.isArray(items)) {
            if (!isProduction) {
                console.error("Items array is not defined or not an array.");
            }
            return;
        }

        // Filter matching product
        const filteredData = items.filter(
            (product: any) => product.item_id === item.product_id
        );

        if (filteredData.length === 0) {
            if (!isProduction) {
                console.warn("No matching product found for removal.");
            }
            return;
        }

        // Update dataLayer for Google Analytics
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
        window.dataLayer.push({
            event: "select_item",
            ecommerce: {
                item_list_id: itemList.itemListId,
                item_list_name: itemList.itemListName,
                items: filteredData,
            },
        });

        if (!isProduction) {
            console.log("DataLayer updated:", {
                event: "select_item",
                ecommerce: {
                    item_list_id: itemList.itemListId,
                    item_list_name: itemList.itemListName,
                    items: filteredData,
                },
            });
        }


        router.push({
            pathname: `/product/${item.product_slug}`,
            query: { store: item.store_id, id: item.product_id },
        });
    };
  return (
      <Grid container>
         

          <Box sx={{
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                  display: 'none',
              },
          }}>
              <IconButton
                  onClick={() => handleScroll('left', scrollContainer)}
                  disableFocusRipple
                  disableRipple
                  sx={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transform: 'translateY(-50%)',
                      fontSize: {
                          xs: "14px",
                          md: "12px"
                      },

                      zIndex: 99,
                      bgcolor: "#FFFFFF",
                      borderRadius: '4px',
                  }}
              >
                  <FontAwesomeIcon icon={faChevronLeft} />
              </IconButton>

              <Box sx={{
                  display: 'flex',
                  overflowX: 'auto',
                  scrollSnapType: 'x mandatory',
                  p: "16px 0",
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      display: 'none',
                  },
              }} ref={scrollContainer}>
                  {dataset?.map((item: any, idx: number) => (
                      <>
                         
                          <div
                              key={item.product_id}
                              onClick={() => handleProductClick(item, itemList)}
                              style={{ cursor: 'pointer' }}
                          >
                              <Card
                                  key={idx}
                                  className="product-card-best-moblie"
                                  sx={{
                                      flex: '0 0 auto',
                                      width: 150,
                                      marginRight: 1.5,
                                      height: "100%",
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',

                                      borderRadius: "14px",
                                      paddingBottom: 1,
                                  }}
                              >
                                  {/* <Box
                                      sx={{
                                          textAlign: 'center',
                                          position: 'relative',
                                          width: '100%',
                                          paddingBottom: '100%',
                                      }}
                                  >
                                      {item.product_thumbnail_image_url && (
                                          <Image
                                              src={item.product_thumbnail_image_url}
                                              alt={item.product_title || 'Product Image'}
                                              fill
                                              loading="lazy"
                                              style={{ objectFit: 'cover' }}
                                          />
                                      )}
                                  </Box>


                                  {
                                      item.product_in_campaign === null ? (
                                          <></>
                                      ) : (
                                          item.product_in_campaign.campaign_product_join ? (
                                              <img
                                                  src={item.product_in_campaign.campaign_product_join.frame_product_fullpath}
                                                  alt="frame product"
                                                  style={{
                                                      position: "absolute",
                                                      zIndex: 9,
                                                      top: "10px",
                                                      left: "0px",
                                                      width: "100%",
                                                  }}
                                              />
                                          ) : null
                                      )
                                  } */}
                                  <Box
                                      sx={{
                                          textAlign: 'center',
                                          position: 'relative',
                                          width: '100%',
                                          paddingBottom: '100%',
                                      }}
                                  >
                                      {isLoading && (
                                          <Skeleton
                                              variant="rectangular"
                                              sx={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  width: "100%",
                                                  height: "100%",
                                              }}
                                          />
                                      )}
                                      <Image
                                          src={item.product_thumbnail_image_url || imgDefault}
                                          alt={item.product_title || imgDefault}
                                          fill
                                          // priority
                                          sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                          style={{ objectFit: 'cover' }}
                                          loading="lazy"
                                          placeholder="empty"
                                          blurDataURL={item.product_thumbnail_image_url || imgDefault}
                                          onLoad={() => setIsLoading(false)}
                                      />
                                  </Box>
                                  {
                                      item.product_in_campaign === null ? (
                                          <></>
                                      ) : (
                                          item.product_in_campaign.campaign_product_join ? (
                                              <img
                                                  src={item.product_in_campaign.campaign_product_join.frame_product_fullpath}
                                                  alt="frame product"
                                                  style={{
                                                      position: "absolute",
                                                      zIndex: 9,
                                                      top: "10px",
                                                      left: "0px",
                                                      width: "100%",
                                                  }}
                                              />
                                          ) : null
                                      )
                                  }
                                  <CardContent sx={{ height: "100%" }}>
                                      <Typography
                                          sx={{
                                              mt: "10px",
                                              display: '-webkit-box',
                                              WebkitBoxOrient: 'vertical',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              WebkitLineClamp: 2,
                                              whiteSpace: 'normal',
                                              color: "#717171",
                                              fontSize: "14px",
                                              lineHeight: "20px"
                                          }}
                                      >
                                          {item.product_title}
                                      </Typography>
                                      <Stack direction={"row"} sx={{ display: "inline-block" }}>
                                          <Typography
                                              sx={{
                                                  fontSize: "18px",
                                                  lineHeight: "20px",
                                                  whiteSpace:"nowrap",
                                                  display: "inline-block",
                                                  marginBottom: 0,
                                                  mr: 1
                                              }}
                                              className="txt-bold"
                                              gutterBottom
                                              color="secondary"
                                          >

                                                {item.product_discounted_price > 0
                                                  ? `฿${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_discounted_price)}`
                                                  : `฿${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_normal_price)}`}
                                          </Typography>
                                          <Box
                                              sx={{
                                                  fontSize: "12px",
                                                  display: "inline-block",
                                                  textDecoration: "line-through",
                                                //   pl: 1,
                                                //   mt: 0.7,
                                                  fontWeight: "bold",
                                                  lineHeight: "14px",
                                                  color: "#AEAEAE"
                                              }}
                                          >
                                              {item.product_discounted_price !== 0 && (
                                                  item.product_normal_price
                                                      ? `฿${item.product_normal_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                                      : ``
                                              )}
                                          </Box>
                                      </Stack>

                                  </CardContent>
                                  <CardActions
                                      sx={{
                                        justifyContent: "space-between",
                                        display: "inline-block",
                                        padding: '10px 15px',
                                        textAlign: 'right'
                                      }}
                                  >
                                      <Box display="inline-flex" sx={{ alignItems: "center", float: "left" }}>
                                          {item?.product_score > 0 && (
                                              <Rating
                                                  name="read-only"
                                                  value={item?.product_score ?? 0}
                                                  precision={0.5}
                                                  readOnly
                                                  size="small"
                                                  sx={{ fontSize: 16 }}
                                                  max={1}
                                              />
                                          )}
                                          {item?.product_score > 0 && (
                                              <Typography
                                                  variant="caption"
                                                  sx={{
                                                    mt: .5, mr: 1, ml: 1, fontSize: 10}}
                                              >
                                                  {/* {parseFloat((item?.product_score ?? 0).toFixed(1))} */}
                                                  {item?.product_score}
                                              </Typography>
                                          )}
                                      </Box>
                                      {item?.product_sold > 0 && (
                                          <Typography
                                              variant="caption"
                                              color="text.secondary"
                                              sx={{ 
                                                fontSize:10, marginLeft: "0 !important"
                                            }}
                                          >
                                              ขายแล้ว {item?.product_sold} ชิ้น
                                          </Typography>
                                      )}
                                  </CardActions>
                              </Card>
                          </div>
                          

                      </>
                  ))}
              </Box>

              <IconButton

                  onClick={() => handleScroll('right', scrollContainer)}
                  disableFocusRipple
                  disableRipple
                  sx={{
                      position: 'absolute',
                      top: '50%',
                      right: 0,
                      transform: 'translateY(-50%)',
                      fontSize: {
                          xs: "14px",
                          md: "12px"
                      },
                      zIndex: 99,
                      bgcolor: "#FFFFFF",
                      borderRadius: '4px',
                  }}
              >
                  <FontAwesomeIcon icon={faChevronRight} />
              </IconButton>
          </Box>
      </Grid>
  )
}