
import React, { useEffect, useState } from 'react'
import {
  Typography,
  Button,
  CardContent,
  Card,
  CardActions,
  CardMedia,
  Rating,
  Box,
  Chip,
  Stack,
  useMediaQuery,
  IconButton,
  Skeleton
} from "@mui/material";

import Link from "next/link";
import Image from "next/image";
import Grid from "@mui/material/Unstable_Grid2";
import { useRouter } from 'next/router';
import {
  ProductsResponse,
} from "../../interfaces/product/product-rec";
import Cookies from "universal-cookie";
import { ProducHomeRecommend } from "@/api/product";
import ProductRecommendRes from "../../components/responsive/productRecommendRes"
import { isProduction } from '@/config';

interface Props {
  dataBannerAds: any
}

interface ProductItem {
  item_id: string;
  item_name: string;
  affiliation?: string | null;
  coupon?: string | null;
  discount: number;
  index: number;
  item_brand?: string | null;
  item_category?: string | null;
  item_category2?: string | null;
  item_category3?: string | null;
  item_category4?: string | null;
  item_category5?: string | null;
  item_list_id: string;
  item_list_name: string;
  item_variant?: string | null;
  location_id?: string | null;
  price: number;
  quantity: number;
}


export default function ProductRecommend({ dataBannerAds }: Props) {
  const router = useRouter();
  const cookies = new Cookies();
  const authToken = cookies.get("token");
  const getToken = authToken !== "" ? authToken : null;
  const [dataRec, setDataRec] = useState([])
  const dataImageRecommend = dataBannerAds?.find((Recommend: any) => Recommend.ads_position === 4);
  const imgDefault = "/assets/images/logo/logomaboom.png"
  const isLargerThan1920 = useMediaQuery('(min-width:1920px)');
  const isLargerThan2160 = useMediaQuery('(min-width:2160px)');
  const isLargerThan2500 = useMediaQuery('(min-width:2500px)');
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const limit = "100"
  const product = "Product"

  const FetChProductRec = async (product: any, getToken: any, limit: any,) => {
    const res = await ProducHomeRecommend(product, getToken, limit,)
    setDataRec(res?.data)

  }

  useEffect(() => {
    FetChProductRec(product, getToken, limit);
  }, [product, limit, getToken]);

  useEffect(() => {
    const updateVisibleProducts = () => {
      const width = window.innerWidth;
      let numberOfCards;

      if (width >= 2160) {
        numberOfCards = 6;
      } else if (width >= 1920) {
        numberOfCards = 5;
      } else if (width >= 1309) {
        numberOfCards = 4;
      } else if (width < 1309) {
        numberOfCards = 4;
      } else {
        numberOfCards = dataRec.length;
      }

      setVisibleProducts(dataRec?.slice(0, numberOfCards));
    };


    updateVisibleProducts();


    window.addEventListener('resize', updateVisibleProducts);


    return () => {
      window.removeEventListener('resize', updateVisibleProducts);
    };
  }, [dataRec]);

  if (dataRec == null || dataRec.length == 0) {
    return '';
  }
  if (typeof dataRec === "object" && dataRec !== null && "message" in dataRec) {
    return "";
  }


  // Format the price to the specified number of decimal places and return as a number
  const formatPrice = (price: number, digits: number = 2): number =>
    parseFloat(price.toFixed(digits));

  const itemList = {
    itemListId: "recommended_products",
    itemListName: "บริการ/สินค้า ที่คุณอาจจะชอบ",
  };

  const handleProductClick = (
    item: any,
    itemList: { itemListId: string; itemListName: string }
  ): void => {
    if (!item) {
      if (!isProduction) {
        console.warn("No product data available.");
      }
      return;
    }

    const items: ProductItem[] = dataRec.map((item, idx) => {
      const {
        product_id,
        product_title,
        product_discounted_price,
        product_normal_price,
        affiliation = null,
        coupon = null,
        item_brand = null,
        item_category = null,
        item_category2 = null,
        item_category3 = null,
        item_category4 = null,
        item_category5 = null,
        item_variant = null,
        location_id = null,
      } = item;

      // Calculate price and discount
      const isDiscounted = product_discounted_price > 0;
      const price = formatPrice(
        isDiscounted ? product_discounted_price : product_normal_price
      );
      const discount = formatPrice(
        isDiscounted ? product_normal_price - product_discounted_price : 0
      );

      return {
        item_id: product_id,
        item_name: product_title,
        ...(affiliation ? { affiliation } : {}),
        ...(coupon ? { coupon } : {}),
        discount,
        index: idx,
        ...(item_brand ? { item_brand } : {}),
        ...(item_category ? { item_category } : {}),
        ...(item_category2 ? { item_category2 } : {}),
        ...(item_category3 ? { item_category3 } : {}),
        ...(item_category4 ? { item_category4 } : {}),
        ...(item_category5 ? { item_category5 } : {}),
        item_list_id: itemList.itemListId,
        item_list_name: itemList.itemListName,
        ...(item_variant ? { item_variant } : {}),
        ...(location_id ? { location_id } : {}),
        price,
        quantity: 1, // Default quantity to 1
      };
    });

    // Check if items array is valid
    if (!items || !Array.isArray(items)) {
      if (!isProduction) {
        console.error("Items array is not defined or not an array.");
      }
      return;
    }

    // Filter matching product
    const filteredData = items.filter(
      (product: any) => product.item_id === item.product_id
    );

    if (filteredData.length === 0) {
      if (!isProduction) {
        console.warn("No matching product found for removal.");
      }
      return;
    }

    // Update dataLayer for Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_id: itemList.itemListId,
        item_list_name: itemList.itemListName,
        items: filteredData,
      },
    });

    // Log data in non-production environments
    if (!isProduction) {
      console.log("DataLayer updated:", {
        event: "select_item",
        ecommerce: {
          item_list_id: itemList.itemListId,
          item_list_name: itemList.itemListName,
          items: filteredData,
        },
      });
    }

    // Navigate to the product page
    router.push({
      pathname: `/product/${item.product_slug}`,
      query: { store: item.store_id, id: item.product_id },
    });
  };




  return (
    <>
      <Grid container sx={{ mt: 3, mb: 1, alignItems: "center" }}>
        <Grid               
          xs={12}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <Typography variant="h2" className="txt-bold"
            sx={{
              fontSize: { xs: '20px', md: '25px' },
              mt: { xs: "5px", md: "0px" },
              mb: 1,
            }}
          > 
            สินค้า/บริการ ที่คุณอาจจะชอบ  
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: { xs: "none", md: "flex" }, height: "100%" }}
      >
        <Grid sm={0} md={0} lg={4} sx={{ mt: "0", display: { sm: "none", md: "inline-block", lg: "inline-block" } }}>
          {isLargerThan2500 ? (
            <Box sx={{
              position: "relative",
              width: "100%",
              mt: 0,
              borderRadius: "12px",
              height: "100%"
            }}>
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "12px",
                  }}
                />
              )}
              <Image
                src={dataImageRecommend?.image_extra_large || imgDefault}
                alt={"banner-img"}
                layout="responsive"
                objectFit="contain"
                height={500}
                width={1}
                // priority
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "12px",
                  objectFit: 'contain',
                }}
                loading="lazy"
                placeholder="empty"
                blurDataURL={dataImageRecommend?.image_extra_large || imgDefault}
                onLoadingComplete={() => setIsLoading(false)}
              />
            </Box>
          ) : isLargerThan2160 ? (
            <Box sx={{ 
                position: "relative", 
                width: "100%", 
                mt: 0, 
                height: "100%" 
              }}
            >
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "12px",
                  }}
                />
              )}
              <Image
                src={dataImageRecommend?.image_extra_large || imgDefault}
                alt={"banner-img"}
                layout="responsive"
                objectFit="contain"
                height={500}
                width={1}
                // priority
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "12px",
                  objectFit: 'contain',
                }}
                loading="lazy"
                placeholder="empty"
                blurDataURL={dataImageRecommend?.image_extra_large || imgDefault}
                onLoadingComplete={() => setIsLoading(false)}
              />
            </Box>
          ) : isLargerThan1920 ? (
            <Box sx={{ 
              position: "relative", 
              width: "100%", 
              mt: 0, 
              height: "auto", 
              borderRadius: "12px" 
            }}>
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "12px",
                  }}
                />
              )}
              <Image
                src={dataImageRecommend?.image_large || imgDefault}
                alt={"banner-img"}
                layout="responsive"
                objectFit="contain"
                height={500}
                width={1}
                // priority
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "12px",
                  objectFit: 'contain',
                }}
                loading="lazy"
                placeholder="empty"
                blurDataURL={dataImageRecommend?.image_extra_large || imgDefault}
                onLoadingComplete={() => setIsLoading(false)}
              />
            </Box>
          ) : (

            <CardContent sx={{ 
              p: 0, paddingBottom: "0 !important",
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              borderRadius: "12px" 
            }}>
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    position: "relative",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "12px",
                  }}
                />
              )}
              <Image
                src={dataImageRecommend?.image_medium || imgDefault}
                alt=""
                layout="intrinsic"
                width={500}
                height={0}
                style={{ width: '100%', height: '100%', borderRadius: "12px" }}
                loading="lazy"
                placeholder="empty"
                blurDataURL={dataImageRecommend?.image_extra_large || imgDefault}
                onLoadingComplete={() => setIsLoading(false)}
              />
            </CardContent>
          )}

        </Grid>
        <Grid sm={12} md={12} lg={8}>
          <Box
            sx={{
              display: 'grid',
              gap: '16px',
              gridTemplateColumns: 'repeat(4, 1fr)',
              width: '100%',
              // mt: '15px',
              "@media (min-width: 1440px)": {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              "@media (min-width: 1920px)": {
                gridTemplateColumns: "repeat(5, 1fr)",
              },
              "@media (min-width: 2160px)": {
                gridTemplateColumns: "repeat(6, 1fr)",
              },
              height: "100%"
            }}
          >
            {visibleProducts.map((item: any) => (

              <div
                key={item.product_id}
                onClick={() => handleProductClick(item, itemList)}
                style={{ cursor: 'pointer' }}
              >
                <Card
                  className="product-card-best"
                  sx={{
                    width: '100%',
                    minHeight: {
                      xs: "300px",
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '14px',
                    transition: 'transform 0.3s, border 0.3s',
                    '&:hover': {
                      borderColor: '#f6cd64',
                      transform: 'translateY(-2px)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      textAlign: 'center',
                      position: 'relative',
                      width: '100%',
                      paddingBottom: '100%',
                    }}
                  >
                    {isLoading && (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    )}
                    <Image
                      src={item.product_thumbnail_image_url || imgDefault}
                      alt={item.product_title || imgDefault}
                      fill
                      // priority
                      style={{ objectFit: 'cover' }}
                      loading="lazy"
                      placeholder="empty"
                      blurDataURL={item.product_thumbnail_image_url}
                      onLoadingComplete={() => setIsLoading(false)}
                    />
                  </Box>
                  {
                    item.product_in_campaign === null ? (
                      <></>
                    ) : (
                      item.product_in_campaign.campaign_product_join ? (
                        <img
                          src={item.product_in_campaign.campaign_product_join.frame_product_fullpath}
                          alt="frame product"
                          style={{
                            position: "absolute",
                            zIndex: 9,
                            top: 0,
                            left: "0px",
                            width: "100%",
                          }}
                        />
                      ) : null
                    )
                  }
                  <CardContent
                    sx={{
                      // height: "auto",
                      padding: "15px"
                      // paddingBottom: "16px"
                    }}>
                    <Typography
                      sx={{
                        mt: "10px",
                        mb: "10px",
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        whiteSpace: 'normal',
                        color: "#717171",
                        fontSize: "16px",
                        lineHeight: "20px"
                      }}
                    >
                      {item.product_title}
                    </Typography>
                    <Stack direction={"row"}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          lineHeight: "20px",
                          whiteSpace: "nowrap"
                        }}
                        className="txt-bold"
                        gutterBottom
                        color="secondary"
                      >

                        ฿ {item.product_discounted_price > 0
                          ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_discounted_price)
                          : new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_normal_price)}
                      </Typography>
                      <Box
                        sx={{
                          fontSize: "12px",
                          display: "inline-block",
                          textDecoration: "line-through",
                          pl: 1,
                          mt: 0.7,
                          fontWeight: "bold",
                          lineHeight: "14px",
                          color: "#AEAEAE"
                        }}
                      >
                        {item.product_discounted_price !== 0 && (
                          item.product_normal_price
                            ? `฿${item.product_normal_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                            : ``
                        )}
                      </Box>
                    </Stack>
                    {item?.products?.product_percentage_discount > 0 ? (
                      <Chip
                        color="error"
                        size="small"
                        label={`ส่วนลด ${Math.trunc(item?.products?.product_percentage_discount)}%`}
                      />
                    ) : (
                      ""
                    )}
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: "space-between",
                      position: 'absolute', bottom: '0',
                      left: '0', right: '0' ,
                      p: '15px 15px 15px 13px'
                    }}
                  >
                    <Box display="inline-flex" sx={{ alignItems: "center" }}>
                      {item?.product_score > 0 && (
                        <Rating
                          name="read-only"
                          value={item?.product_score ?? 0}
                          precision={0.5}
                          readOnly
                          size="small"
                          sx={{ fontSize: 16 }}
                          max={1}
                        />
                      )}
                      {item?.product_score > 0 && (
                        <Typography
                          variant="caption"
                          sx={{ ml: .5, fontSize: 12 }}
                        >
                          {Number(item?.product_score ?? 0).toFixed(1)}
                          {/* {item?.product_score} */}
                        </Typography>
                      )}
                    </Box>
                    {item?.product_sold > 0 && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontSize: 12 }}
                      >
                        ขายแล้ว {item?.product_sold} ชิ้น
                      </Typography>
                    )}
                  </CardActions>
                </Card>
              </div>

            ))}
          </Box>
        </Grid>
      </Grid>

      {product && (
        <Grid container spacing={2} sx={{ display: { xs: "flex", md: 'none' } }}>
          <ProductRecommendRes
            dataRec={dataRec}
            dataImageRecommend={dataImageRecommend} />
        </Grid>
      )}

      {router.pathname === '/product/[slug]' ? (
        <>

        </>
      ) : (
        <Box sx={{ mt: 3 }} className="pagination">
          <Button
            variant="outlined"
            disableRipple
            sx={{
              borderColor: "#DEE2E6", width: "300px", color: "#292929",
              '&:hover': {
                color: "#DB5A00",
                backgroundColor: "#ffffff"
              },
            }}
            onClick={() => {
              router.push("/product/Productrecommend-All");
            }}
          >
            <Typography>ดูสินค้า/บริการเพิ่มเติม</Typography>
          </Button>
        </Box>
      )}
    </>
  );
}
